.ui--home {
  &-container {
  }

  &-banner {
    .slide {
      @apply mx-auto h-96 w-full max-sm:h-auto;
    }

    .slick {
      &-arrow {
        display: none !important;
      }

      &-list {
        @apply relative m-0 overflow-hidden p-0;
        transform: translateZ(0);
      }

      &-track {
        @apply mx-auto flex;
      }

      &-slide {
        @apply h-full;
      }
    }
  }

  &-games {
    .btn {
      &-tab {
        @apply grid-flow-row;

        &.active {
          .icon {
            @apply bg-theme;

            &.svg {
              @apply text-theme-dark;
            }
          }

          .text {
            @apply text-theme;
          }
        }

        .icon-svg {
          @apply size-7 text-theme-light;
        }

        .text {
          @apply text-theme-light;
        }
      }
    }
  }
}
