.ui--blog {
  &-container {
    @apply mt-4 grid grid-cols-3 gap-4 rounded-2xl bg-theme-dark p-4 max-sm:grid-cols-2;
  }

  &-content {
    @apply mt-4 rounded-2xl bg-theme-dark p-4;
  }

  &-item {
    @apply overflow-hidden rounded-lg bg-theme-light ring-1 ring-white/30;

    .title {
      @apply text-lg font-bold text-theme;
    }
  }
}