.ui--dropdown {
  @apply absolute inset-x-0 top-[105%] z-10 overflow-hidden rounded-lg bg-white;

  &:not(.visible) {
    @apply hidden;
  }

  &-group {
    @apply grid overflow-x-hidden p-2;
    max-height: 275px;

    @include screen-sm {
      max-height: 250px;
    }
  }

  &-list {
    @apply rounded-md p-2;

    &.focus,
    &:hover {
      @apply cursor-pointer bg-slate-200;
    }
  }

  .empty {
    @apply p-2 text-theme-light;
  }
}
