.ui--login {
  &-modal {
    @apply min-w-96 px-10 pb-10 pt-14;
  }

  &-header {
  }

  &-form {
  }

  &-footer {
    .btn {
      &-addon {
        @apply size-12 rounded-xl hover:bg-theme-dark;
        box-shadow:
          0 0 0 3px rgb(10 10 10),
          0 0 0 4px rgb(25 25 25);
      }
    }
  }
}
