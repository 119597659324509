@layer components {
  .ui--markdown {
    &-blog {
      h1 {
        @apply text-theme text-left text-2xl;
      }

      h2,
      h3 {
        @apply text-theme text-center text-2xl font-semibold mt-6;
      }

      p {
        @apply my-2;
      }

      a {
        @apply text-theme;
      }

      blockquote {
        @apply border-l-4 border-theme-red my-4;

        a {
          @apply text-theme w-full text-center;
        }

        img {
          @apply my-0;
        }
      }

      pre {
        @apply text-center text-base font-semibold;
      }

      ul,
      ol {
        @apply list-disc pl-10 mt-4 mb-4;
      }

      li {
        @apply mb-1;
      }

      img {
        @apply mx-auto my-6 rounded;
      }

    }

    &-promotion {

      ul,
      ol {
        @apply list-disc pl-5 mt-1 mb-3 text-sm;
      }
    }
  }
}