@layer components {
  .btn {
    @apply relative grid grid-flow-col items-center justify-center gap-1 rounded-md leading-5 duration-150 ease-out;

    * {
      @apply pointer-events-none;
    }

    &-text {
      &:hover {
        .text {
          @apply underline;
        }
      }
    }

    &-primary {
      @apply rounded-full text-black;
      background-image: linear-gradient(to bottom, #4bff9f 0%, #20c76e 100%);
    }

    &:disabled,
    &.disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }
}
